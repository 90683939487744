// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cocktails-js": () => import("./../../../src/pages/cocktails.js" /* webpackChunkName: "component---src-pages-cocktails-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-engagement-js": () => import("./../../../src/pages/engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-from-our-friends-js": () => import("./../../../src/pages/fromOurFriends.js" /* webpackChunkName: "component---src-pages-from-our-friends-js" */),
  "component---src-pages-in-memoriam-js": () => import("./../../../src/pages/in-memoriam.js" /* webpackChunkName: "component---src-pages-in-memoriam-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/ourStory.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-proposal-js": () => import("./../../../src/pages/proposal.js" /* webpackChunkName: "component---src-pages-proposal-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-save-the-date-js": () => import("./../../../src/pages/saveTheDate.js" /* webpackChunkName: "component---src-pages-save-the-date-js" */),
  "component---src-pages-us-in-love-js": () => import("./../../../src/pages/usInLove.js" /* webpackChunkName: "component---src-pages-us-in-love-js" */),
  "component---src-pages-wedding-day-js": () => import("./../../../src/pages/weddingDay.js" /* webpackChunkName: "component---src-pages-wedding-day-js" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-pages-wedding-party-js": () => import("./../../../src/pages/weddingParty.js" /* webpackChunkName: "component---src-pages-wedding-party-js" */)
}

